=handler-vert
  width: 3px
  height: 100%
  position: absolute !important
  cursor: ew-resize

=handler-horiz
  height: 3px
  width: 100%
  position: absolute !important
  cursor: ns-resize

=handler-left
  +handler-vert
  left: 0px !important

=handler-right
  +handler-vert
  right: 0px !important

=handler-top
  +handler-horiz
  top: 0px !important

=handler-bottom
  +handler-horiz
  bottom: 0px !important

=handler-tree-over
  box-shadow: inset 13px 0px 20px -20px black

=handler-details-over
  box-shadow: -13px 0px 20px -20px black

=handler-search-over
  box-shadow: 0px -14px 20px -20px black

=handler-resizable-panel
  &:not(.hidden)
    display: flex
    //flex-direction: row  !important
    flex: initial
    position: relative !important
    right: 0 !important
