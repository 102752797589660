=database-db-modules
  #db-module-sidebar
    max-height: calc(100vh - 39vh)
    overflow: auto

  .db-module-menu-sidebar
    ul
      padding-left: 1rem
    a
      padding: 0.2rem

  .border-left
    border-left: 1px solid $border-color

  .db-module-header
    background-color: $body-bg
    ul.nav-tabs li.nav-item
      font-weight: 500
      font-size: 1rem

  #information
    .sticky-top
      top: 300px
