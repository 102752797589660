html
  scrollbar-color: var(--scrollbar-color) var(--scrollbar-color-contrasted)
  font-size: 10pt

// Prevents scrollbar in form popups
form
  margin-block-end: 0

.glyph.type-cs_phase
  color: $danger
.glyph.type-cs_transport_step
  color: $cyan
.glyph.type-cs_energy_consumption
  color: $cyan
.glyph.type-cs_container
  color: $yellow
.glyph.type-cs_container.type-cs_container-dossier
  color: var(--cs-container-dossier-color)
.glyph.type-cs_container.type-cs_container-subassembly
  color: var(--cs-container-subassembly-color)
.glyph.type-cs_container.type-cs_container-part
  color: var(--cs-container-part-color)
.glyph.type-cs_container.type-cs_container-system
  color: var(--cs-container-system-color)
.glyph.type-cs_container.type-cs_container-phase
  color: var(--cs-container-phase-color)
.glyph.type-cs_module, .glyph.type-db_module
  color: var(--cs-module-color)
.glyph.type-cs_module.type-cs_module-material, .glyph.type-db_module.type-db_module-material
  color: var(--cs-module-material-color)
.glyph.type-cs_module.type-cs_module-component, .glyph.type-db_module.type-db_module-component
  color: var(--cs-module-component-color)
.glyph.type-db_category
  color: $yellow

// Fix uneven glyph sizes
.glyph.fas.fa-comments, .glyph.fas.fa-lock, .glyph.fas.fa-user
  width: 0.9em

// Allow disabled dropdown items icons to have visible title on hover
.dropdown-item.disabled i.glyph[title]
  pointer-events: auto
  cursor: default

// Black and white breadcrumb in forms
.make-form.panel > .panel-body.breadcrumb .glyph,
ol.breadcrumb > li.breadcrumb-item .glyph
  color: unset

.badge
  padding: 0.5em 0.65em 0.3em

.badge-info
  background-color: $design-color

.badge-comparison
  background-color: $comparison-color

.fix-label
  margin-right: 0px

.visible
  display: block !important

.hidden
  display: none !important

.context-menu
  display: block

.no-select
  -webkit-touch-callout: none
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

.notify-container
  position: fixed
  top: 7.5px
  right: 7.5px
  z-index: 9999

.help-block
  padding-left: 4px
  font-size: 0.9rem

label
  font-weight: bold

.popup
  padding: 4rem 2rem

.has-error
  .help-block
    color: $danger
    font-style: italic
    font-size: unset


.input-group
  // Apply zero border radius to input and select elements of an input group,
  // with special rules for first or last element that should be rounded.
  & > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) > input,
  & > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) > select
    border-top-left-radius: 0
    border-bottom-left-radius: 0

  & > :not(:last-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) > input,
  & > :not(:last-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) > select
    border-top-right-radius: 0
    border-bottom-right-radius: 0

.only-form
  font-size: 1rem
  label
    font-weight: 500
    font-size: 0.9rem
  li.dropdown
    form
      margin-bottom: 0rem
  //.make-form.panel
    height: calc(100vh - 4rem)
  .form-group:not(:last-child)
    margin-bottom: 1rem
  .form-group abbr[title]
    text-decoration: none
    color: red
  .form-icon
    width: 12px
  .form-check-input[disabled] ~ .form-check-label
    opacity: 1
  .form-select:disabled
    background-image: none

.notify
  max-width: 500px

.progress-bar
  color: white

.fa-layers svg:last-child
  color: $body-bg !important

.badge-project
  background-color: $project-color
  color: $project-color-contrasted

.btn-project
  background-color: $project-color !important
  border-color: darken($project-color, 20%) !important

.btn-analysis
  background-color: $analysis-color !important
  border-color: darken($analysis-color, 20%) !important

.btn-design
  background-color: $design-color !important
  border-color: darken($design-color, 20%) !important

.btn-comparison
  background-color: $comparison-color !important
  border-color: darken($comparison-color, 20%) !important
  color: white

.btn-outline-project
  border-color: darken($project-color, 20%) !important
  &:not(:hover)
    color: $project-color !important
  &:hover
    background-color: $project-color !important

.btn-outline-analysis
  border-color: darken($analysis-color, 20%)
  &:not(:hover)
    color: $analysis-color !important
  &:hover
    background-color: $analysis-color !important

.btn-outline-design
  border-color: darken($design-color, 20%)
  &:not(:hover)
    color: $design-color !important
  &:hover
    background-color: $design-color !important

.btn-outline-comparison
  border-color: darken($comparison-color, 20%) !important
  &:not(:hover)
    color: $comparison-color !important
  &:hover
    background-color: $comparison-color !important

.btn-outline-database
  border-color: darken($database-color, 20%)
  &:not(:hover)
    color: $database-color
  &:hover
    color: white
    background-color: $database-color

.glyph.outline
  //color: $body-bg
  //text-shadow: -1px -1px 0 $body-color, 1px -1px 0 $body-color, 1px 1px 0 $body-color, 1px -1px 0 $body-color
  color: transparent
  -webkit-text-stroke-width: 1.2px
  -webkit-text-stroke-color: $body-color

// ------------ Change scrollbar to chrome browser -----------------------------
\::-webkit-scrollbar
  width: 8px
  height: 8px
  border-left: 1px solid $border-color
  background-color: rgba(0, 0, 0, 0)
  -webkit-border-radius: 0px
  &:hover
    background-color: rgba(0, 0, 0, 0.09)

\::-webkit-scrollbar-thumb
  background: rgba(0, 0, 0, 0.3)
  -webkit-border-radius: 100px
  &:active
    background: rgba(0, 0, 0, 0.41)
    -webkit-border-radius: 100px
  &:vertical
    min-height: 10px
  &:horizontal
    min-width: 10px

.view li input[type=checkbox]:checked + label .glyph.fa-exclamation-triangle
  color: $danger !important

.flag
  height: 1rem

.table-information
  @extend .table
  @extend .table-bordered
  th
    background-color: $gray-200
    width: 20%
  td
    padding: 0.5rem !important

fieldset
  padding: 0.75rem !important
  margin-bottom: .75rem
  border: solid 1px var(--border-color)
  border-radius: $border-radius
  background-color: $light
  legend
    font-size: 1rem
    width: auto
    margin: 0
    padding: 0
    float: inherit

mark
  text-shadow: none
  background-color: $warning
  border-radius: $border-radius

.dropdown-menu
  .glyph
    width: 16px
    text-align: center
    margin-right: 2px
  .right-arrow
    float: right
    margin-right: -.5rem
    .glyph
      text-align: right


// Fix table headers with links in them

a
  text-decoration: none
  // Remove by Thomas
  //&:hover
    text-decoration: underline

*[tabindex]
  outline: none

// Fix danger Button
.btn-danger
  color: white
  &:hover, &:focus
    color: white

// Remove blue color from accordion header
.accordion .accordion-header .accordion-button
  color: unset

.navbar__danger-bullet
  position: relative
  left: -0.5rem
  top: -0.5rem
  display: inline-block
  border-radius: 1em
  background-color: var(--danger-color)
  width: 8.5px
  height: 8.5px

.form-control .form-control-disabled, .form-control .form-control-readonly
  background-color: var(--bs-light)
