@import "../mixins/treeview"
//@import "../components/c_database_layout"

html.interface-comparison
  --main-panel-height: calc(100vh - 4rem)

  ol.c-treeview__children > li:nth-of-type(odd)
    background-color: var(--bs-gray-200)

  .comparison
    height: calc(var(--main-panel-height))
    display: flex
    position: relative !important
    padding: 0
    flex: initial
    flex-flow: row nowrap
    //// .tab-pane
      height: calc(100vh - 32vh)

    .values-body
      background-color: #e6e6e6
      padding: 0
      #all-indicators-charts
        height: 100%
        background-color: white
        .chart-panel
          height: 90% !important

      .table-bordered
        margin-top: 0px !important
        margin-bottom: 0px !important
        td:first-child
          border-left: none
          white-space: nowrap
        td:last-child, th:last-child
          border-right: none

    .tab-pane
      .btn-toolbar
        background-color: #f7f7f7
        border-bottom: 1px solid $border-color

    .table-loader
      width: 4rem
      height: 4rem

    .values, .values-body .panel-footer
      position: relative
      +overflow-scroll-overlay

    .table tbody tr:nth-of-type(odd) td
      background-color: darken($body-bg, 5)

    .table tbody tr:nth-of-type(even) td
      background-color: $body-bg

    .table td, .table th, .table
      border: none !important

    .column-truncate
      max-width: 200px

    .table thead th
      box-shadow: inset -1px 1px 0px 0px $border-color
      small
        font-style: italic
        font-size: 0.60rem

    .table tbody td
      white-space: nowrap
      box-shadow: inset -1px 1px 0px 0px $border-color

    .table tbody td:not(:first-child),
    .table tfoot tr th:not(:first-child)
      text-align: right

    .table tfoot tr th
      background-color: darken($body-bg, 10)

    table td.negative, table th.negative
      background-color: lighten($success, 30) !important

    .table
      overflow: hidden

    ol.breadcrumb
      margin: 0

    #tree
      //height: calc(100vh - 4rem - 40px)
      flex: initial
      //width: 25%
      display: flex
      //right: 0 !important
      //z-index: 999
      .panel-header
        display: inline
      .tree-body
        padding: 0
        overflow: hidden
        ol.treeview
          overflow-y: auto
          a.disabled
            pointer-events: none
            cursor: default
            color: #888 !important
          li
            .spinner
              display: none
              position: absolute
              right: 10px
            input[type=checkbox]
              opacity: 1
              transform: none
            .badge
                position: absolute
                border-radius: 1rem
                display: block
                width: 1px
                height: 1px
                padding: 4px
                margin-left: 22px
                margin-top: 4px
            .select-children
              position: absolute
              right: .5rem
              cursor: pointer
            label
              display: flex
              justify-content: flex-start
              div:last-child
                margin-left: auto
          li:not(.active)
            &>label
              font-weight: normal
              &>span.caret
                color: $black !important
              &>a
                color: $black
              &>i
                color: $black
              &>.ico_popover
                color: $secondary
          li.active
            background-color: $comparison-color
            &>label
              &>a
                color: white
              &>i
                color: white
          label:not(.active):hover
            background-color: rgba($comparison-color, 0.25)
        li>label>.ico_popover
          display: none
          padding-rigth: 6px
          padding-left: 6px
          font-size: 0.7rem
          color: $secondary
          &>i
            padding-right: 40px
        li>label:hover>.ico_popover
          display: block
      .tree-body:hover
        overflow: overlay
      .tree-footer, .tree-footer:hover
        overflow: none !important

  .comparison-items-form
    width: 125px

  .threshold_class
    input
      width: 3rem
    select
      width: 8rem
