.treeview
  display: block
  padding-left: 0
  margin-left: 0
  li
    list-style: none
    margin-left: 0
    padding-left: 0
    margin: 0
    padding: 0
    border: none
    &.expanded
      display: block

      & > label
        & > .caret:before
          font-family: 'Font Awesome 6 Free'
          font-weight: 900
          content: ''
          position: static
      & > ol.analysis_item
        display: block

    & input[type=radio], & input[type=checkbox]
      // trick the browser in thinking the input is visible so it is focusable
      position: absolute
      opacity: 0
      transform: translate(-0.5em, -0.5em) scale(0.1)

    & label
      padding: 0.1rem
      margin-left: 0
      display: flex
      margin-bottom: 0
      width: 100%
      align-items: center
      &.current
        background-color: $secondary
        a
          color: white
      & > i
        margin-right: 0.3rem

      & a
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis

      & span
        &.caret, &.blank-span
          width: 15px !important
          line-height: 12px
          height: 12px
          flex: none
        &.caret:before
          -webkit-font-smoothing: antialiased
          font-family: 'Font Awesome 6 Free'
          font-weight: 900
          content: ''
          position: static

    .text
      margin-left: 3px !important
      white-space: nowrap

    & ol:not(.c-treeview__children)
      display: none
      padding-left: 0
      margin-left: 0

@for $i from 0 through 20
  .treeview li label.indent-#{ $i }, .treeview li .label.indent-#{ $i }
    padding-left: 10px + (15px * $i)

.treeview li label.indent-0, .treeview li .label.indent-0
  padding-left: 10px

.treeview.view li input[type=radio]:checked + label
  box-shadow: 0px 0px 0px 1px $border-color
  //// border-top: 1px solid $border-color
  //// border-bottom: 1px solid $border-color

.treeview
  svg
    width: 100px
    display: block

  .caret:before
    -webkit-font-smoothing: antialiased
    font-family: 'Font Awesome 6 Free'
    font-weight: 900
    content: ''
    position: static
  span
    &.caret, &.blank-span
      width: 15px !important
      line-height: 0.5rem
      height: 12px
      flex: none
    &.caret:before
      -webkit-font-smoothing: antialiased
      font-family: 'Font Awesome 6 Free'
      font-weight: 900
      content: ''
      position: static
