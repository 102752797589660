@import ../../mixins/overflow

.design
  .library
    //flex: initial !important
    border: none
    & > .panel-header
      z-index: 10
      padding: 0
      border: none
      background-color: unset
      height: calc(2rem + 1px)
      .panel-header-tabs
        .glyph
          color: unset
        margin-bottom: 0
        .nav-link
          padding-top: 0.25rem
          padding-bottom: 0
          height: 100%
        .nav-link.active
          // opacify the $panel-cap-bg color using $body-bg instead of a transparent background
          // $neutral_border_color: rgb(red($body-bg) * (1 - opacity($panel-cap-bg)) + red($panel-cap-bg) * opacity($panel-cap-bg), green($body-bg) * (1 - opacity($panel-cap-bg)) + green($panel-cap-bg) * opacity($panel-cap-bg), blue($body-bg) * (1 - opacity($panel-cap-bg)) + blue($panel-cap-bg) * opacity($panel-cap-bg))
          // border-bottom: 1px solid $neutral_border_color
          // background-color: $panel-cap-bg
          border-bottom: 1px solid rgb(247, 247, 247)
          background-color: rgba(0, 0, 0, 0.03)
    & > .tab-content
      border: 1px solid $border-color
      border-bottom-left-radius: 0.25rem
      border-bottom-right-radius: 0.25rem
    & > .panel-toolbar
      border: 1px solid $border-color
      border-bottom-left-radius: 0.25rem
      border-bottom-right-radius: 0.25rem

    .text
      display: inline
    .square
      width: 20px
      height: 10px
      display: inline-block
    .referent
      font-weight: bold
    .block-line
      label
        text-decoration: line-through
    //max-height: calc(100vh - 15vh)
    width: 25%
    //max-width: 100px
    min-width: 305px
    flex: initial
    position: relative !important
    left: 0 !important
    .search-div
      padding: $card-spacer-y
      border-bottom: 1px solid $border-color
      background-color: $panel-cap-bg
    .nav-item
      .nav-link
        color: $nav-link-color
    .text-class
      text-align: right
    .title-label
      font-weight: bold
      display: block
      font-size: large
      text-align: center
      margin: 0 auto
    .pnl-body
      top: auto !important
      display: flex
      flex-direction: column
      border-right: 1px solid $border-color
      border-left: 1px solid $border-color
      .card
        display: flex
        flex-direction: column
        border: none
        border-top:  1px solid $border-color
        height: 100%
        .card-body
          overflow-y: overlay
          flex: 1 1 auto
          min-height: 0px
          .delete-comparison
            border-top-left-radius: 0px
            border-top-right-radius: 0px

        .comparison
          border: none
          border-top:  1px solid $border-color
          .form-group
            margin-bottom: 0px

    .tab-content
      display: flex
      .tab-pane.active
        display: flex !important
        flex-direction: column
        width: 100%
      .tab-pane
        div:first-child
          position: relative !important
          nav
            margin: 0 auto
        .library-tree-body
          display: flex
          flex-flow: column nowrap
          .list-group
            flex: 1 1 auto
            overflow-y: scroll
            @supports (overflow-y: overlay)
              overflow-y: overlay
          height: 20px
          overflow: hidden
          position: relative !important
          flex: 1 1 auto

        .library-tree-footer
          background-color: rgba(0, 0, 0, 0.03)
          min-height: calc(var(--search-toolbar-height) + 1px)
          border-top: 1px solid #dee2e6 !important
          display: flex
          flex-direction: column
          gap: 0.25rem
          justify-content: center
          align-items: center
          .page-entries
            display: flex
            align-items: center
            gap: 0.25rem
            height: var(--search-toolbar-height)
            font-weight: 500

        .list-group

          a[href="#"]:hover
            text-decoration: none
          .list-group-item:first-child
            border-top: none
          & > .list-group-item:last-child
            border-bottom: 1px solid $border-color
          .list-group-item
            border-radius: 0px
            border: none
            border-top: $list-group-border-width solid $list-group-border-color
          .list-group-item:nth-of-type(odd)
            background-color: $table-accent-bg


.library-handler
  width: 3px
  height: 100%
  position: absolute !important
  left: 0px !important
  cursor: ew-resize

.library-comparison-handler
  height: 3px
  width: 100%
  cursor: ns-resize

.library-handler-over
  box-shadow: -13px -1px 20px -20px black

.library-comparison-handler-over
  box-shadow: 0px -14px 20px -20px black

.library
  .pagination
    margin: 0 auto 0.4rem

.library .input-group .form-control
  width: 100%

.filter
  width: 460px
  padding: 1rem 1.5rem
  .btn-group
    display: block
    button
      width: 100%
