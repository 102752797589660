html.toolbar-horizontal
  // compute line-height  to account for a small toolbar button size
  // 0.74375rem is the font-size of a button
  // * 1.5 which is the line-height of a button
  // + 0.5rem for twice the button padding
  // + 2px for twice the button border
  // + 1rem for twice the toolbar padding
  --toolbar-height: calc(0.74375rem * 1.5 + 0.5rem + 2px + 1rem)
  // toolbar full height with border
  --toolbar-full-height: calc(var(--toolbar-height) + 1px)

html.toolbar-vertical
  --toolbar-height: 0px
  --toolbar-full-height: var(--toolbar-height)

=toolbar-space-after($dim)
  html.toolbar-horizontal &
    margin-right: $dim
  html.toolbar-verticala &
    margin-bottom: $dim

=toolbar-apply-browser-panel-vertical
  flex-direction: row
  .browser-toolbar ~ .panel
    .overview-header
      border-top-right-radius: 0.25rem
    .search .btn-toolbar
      border-bottom-left-radius: 0
  .browser-toolbar
    border-top-left-radius: 0.25rem
    border-bottom-left-radius: 0.25rem
    padding-left: 0
    border-bottom: none
    border-right: 1px solid $card-border-color

    // It's not possible to set flex-direction to column because of bugs in all
    // major browsers. Instead, flex direction is kept to row but the writing
    // mode is changed to use a vertical writing mode (reset in the child
    // elements) to get a column effect.
    // The problem is that when wrapping buttons in case the viewport is too
    // small, the next buttons were overflowing the toolbar instead of
    // increasing the toolbar width.
    // Links:
    //   https://stackoverflow.com/questions/33891709/when-flexbox-items-wrap-in-column-mode-container-does-not-grow-its-width
    //   https://stackoverflow.com/questions/23408539/how-can-i-make-a-displayflex-container-expand-horizontally-with-its-wrapped-con/41209546#41209546
    //   https://bugs.chromium.org/p/chromium/issues/detail?id=507397
    //   https://bugzilla.mozilla.org/show_bug.cgi?id=995020
    writing-mode: vertical-lr
    // flex-direction: column
    .btn-group, .btn-group-vertical
      writing-mode: horizontal-tb

    .btn-group, .btn-group-vertical
      flex-direction: column
      margin-right: 0 !important // Fix bootstrap/utilities/_spacing.scss:16
      margin-left: 0.5rem
    .item-adding a.btn .glyph
      text-shadow: 0.5px 0.5px $gray-700
    .dropdown
      margin: 0
      padding: 0
    .btn
      // Ensure all buttons have the same size and scale down a bit to fit in
      // vertical mode
      width: 1.75rem
      height: 1.75rem
      padding: calc(0.25rem * 0.75)
    .btn-group-vertical.actions-menu, .btn-group.actions-menu
      order: -20
    .btn-group-vertical.item-adding, .btn-group.item-adding
      order: -10
    .btn-group-vertical .btn.hidden:first-child + .btn:not(.hidden)
      border-top-left-radius: 0.2rem
      border-top-right-radius: 0.2rem
      margin-top: 0
    & > *:not(:last-child):not(.toolbar-space-after):not(.toolbar-space-after-2)
      margin-bottom: 0.25rem
    & > .toolbar-space-after-2
      margin-bottom: 2rem
    & > .toolbar-space-after
      margin-bottom: auto

=toolbar-apply-browser-panel-horizontal
  .browser-toolbar
    border-top-left-radius: 0.25rem
    border-top-right-radius: 0.25rem
    .btn-group-horizontal .btn.hidden:first-child + .btn:not(.hidden)
      border-top-left-radius: 0.2rem
      border-bottom-left-radius: 0.2rem
      margin-left: 0
    & > *:not(:last-child):not(.toolbar-space-after):not(.toolbar-space-after-2)
      margin-right: 0.25rem
    & > .toolbar-space-after-2
      margin-right: 2rem
    & > .toolbar-space-after
      margin-right: auto

=toolbar-apply-browser-panel
  html.toolbar-vertical &
   +toolbar-apply-browser-panel-vertical
  html.toolbar-horizontal &
   +toolbar-apply-browser-panel-horizontal

=toolbar-apply-root
  .browser.panel
    +toolbar-apply-browser-panel
