// Implements ellipsis and overflow for table cells

.table-cell-overflow td
  max-height: 1em
  white-space: nowrap

td > div.table-cell-overflow
  display: inline-block
  position: relative
  white-space: normal
  overflow: hidden
  vertical-align: top
  max-height: 1.5em

  &.nowrap
    white-space: nowrap
    text-overflow: ellipsis

  &:hover
    z-index: 1
    width: auto
    max-height: none
