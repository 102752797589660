// Set up variables. Default variables will override ui-bibz and bootstrap
// variables while application variables will create the application specific
// variables from ui-bibz and bootstrap defaults

@import "theme/default_variables"
@import ~ui-bibz-js/sass/ui-bibz
@import "theme/application_variables"

// Specific styles for some parts of the app
@import "utils/navbar"
@import "utils/search"
@import "utils/drop-target"
@import "utils/dropdown"
@import "utils/overflow"
@import "utils/tableview"
@import "utils/treeview"
@import "utils/forms"


// Specific styling depending on the html.interface-* CSS selector
@import 'interfaces/admin.sass'
@import 'interfaces/analysis.sass'
@import 'interfaces/comparison.sass'
@import 'interfaces/database.sass'
@import 'interfaces/design.sass'
@import 'interfaces/devise.sass'
@import 'interfaces/make.sass'
@import 'interfaces/project.sass'

// Global styles
@import "theme/global"

// Fix bootstrap style causing multiselect to have too much height
// node_modules/ui-bibz-js/vendor/css/bootstrap-multiselect.sass:95
.input-group
  &.input-group-sm
    & > .multiselect-native-select
      .multiselect
        height: unset
        // was: height: calc(4em)
