=admin-communications
.communication
      height: calc(var(--main-panel-height))
.c-communication-layout__overview
    --c-tableview--communication-name-width: 25%
    --c-tableview--communication-higlighted-width: 5%
    --c-tableview--communication-publication-start-date-width: 15%
    --c-tableview--communication-publication-end-date-width: 15%
    --c-tableview--communication-user-width: 15%
    --c-tableview--communication-description-width: 25%
    --c-tableview--communication-short-description-width: 15%
