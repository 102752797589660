@import 'database/db_modules'

html.interface-database

  --selection-bgcolor: #5b5b5b
  --selection-fgcolor: white
  --hover-bgcolor: #1a343a3b
  --hover-fgcolor: black

  h1
    font-size: 1.6rem
  h2
    font-size: 1.3rem

  +database-db-modules

  .c-database-layout__overview
    --c-tableview--unit-group-name-width: 8.5rem
    --c-tableview--unit-description-width: 20em
    --c-tableview--unit-name-width: 9em
    --c-tableview--unit-ratio-width: 12em
    --c-tableview--unit-unit-system-name-width: 6em

    --c-tableview--db-flow-name-width: 15rem
    --c-tableview--db-flow-element-type-width: 9rem
    --c-tableview--db-flow-unit-group-name-width: 8.5rem
    --c-tableview--db-flow-provider-width: 6rem
    --c-tableview--db-flow-ext-id-width: 12rem
    --c-tableview--db-flow-ext-version-width: 8.5rem
    --c-tableview--db-flow-location-width: 4rem
    --c-tableview--db-flow-instance-width: 9rem
    --c-tableview--db-flow-path-width: 15rem

    --c-tableview--db-module-name-width: 15rem
    --c-tableview--db-module-element-type-width: 6rem
    --c-tableview--db-module-unit-group-name-width: 8.5rem
    --c-tableview--db-module-provider-width: 6.5rem
    --c-tableview--db-module-ext-id-width: 12rem
    --c-tableview--db-module-ext-version-width: 7rem
    --c-tableview--db-module-location-width: 4rem
    --c-tableview--db-module-instance-width: 9rem
    --c-tableview--db-module-path-width: 15rem
    --c-tableview--db-module-ecobilan-identifier-width: 82px
    --c-tableview--db-module-reference-year-width: 4rem
    --c-tableview--db-module-available-date-width: 6rem
    --c-tableview--db-module-quality-width: 5rem
    --c-tableview--db-module-workflow-and-publication-status-width: 16rem

    --c-tableview--tag-name-width: 10rem
    --c-tableview--tag-colors-width: 5rem

    --c-tableview--indicators-set-name-width: 13rem
    --c-tableview--indicators-set-year-width: 5.5rem
    --c-tableview--indicators-set-indicators-type-width: 12rem
    --c-tableview--indicators-set-node-name-width: 9rem
    --c-tableview--indicators-set-available-date-width: 10.5rem

    --c-tableview--indicators-set-child-name-width: 13rem
    --c-tableview--indicators-set-child-type-id-width: 9rem
    --c-tableview--indicators-set-child-indicator-type-width: 12rem

    --c-tableview--indicator-name-width: 15em
    --c-tableview--indicator-method-width: 6em
    --c-tableview--indicator-source-width: 15em
    --c-tableview--indicator-abbreviation-width: 9em
    --c-tableview--indicator-year-width: 5.5em
    --c-tableview--indicator-unit-width: 6em
    --c-tableview--indicator-ext-id-width: 15em
    --c-tableview--indicator-ext-version-width: 8.5em
    --c-tableview--indicator-indicator-type-width: 15em
    --c-tableview--indicator-request-width: 15em
    --c-tableview--indicator-inies-code-width: 6em
    --c-tableview--indicator-nodes-width: 6em
    --c-tableview--indicator-available-date-width: 10.5rem

  // Fixup dropdown select field with size small
  .input-group-sm.ui_surround_field > .dropdown-select-field > button
    min-height: unset
    // Same as a small button
    padding: 0.25rem 0.5rem
    font-size: 0.74375rem

  .details
    +details-apply($database-details-width, $details-max-width)
    +handler-resizable-panel
    left: unset !important // For some reason jquery-ui wnts to set it
