@import './tableview'

=search-searching
  .panel-group
    height: 40vh
    #tree
      height: 60vh
    #overview
      height: 30vh

=search-search
  &.panel
    border-top-left-radius: 0
    border-top-right-radius: 0
  .panel-body
    display: none
    padding: 0

  &.open
    min-height: 200px
    .panel-body
      display: flex
      border-bottom: none
      .view
        min-height: 5rem
        height: 5rem
    .btn-toolbar
      border-bottom-left-radius: 0px
      border-bottom-right-radius: 0px

  &:not(.open)
    .panel-toolbar
      border-bottom: none !important

  position: relative !important
  border: none
  border-top: 1px solid $border-color
  border-radius: $border-radius
  bottom: 0px !important
  top: auto !important
  max-height: 70vh

  @for $i from 1 through 10
    .t-col-#{ $i } .t-cell
      width: calc(100% / #{$i})

  .t-row
    .check
      flex-basis: 40px   !important
      text-align: left
    .ico
      flex-basis: 40px   !important
    .name
      flex-basis: 10% //fit-content
      flex-grow: 1
      text-align: left !important
      a
        word-wrap: wrap
    .state
      flex-basis: 65px   !important
      width: 65px   !important
    .quantity
      flex-basis: 80px   !important
    .id
      flex-basis: 40px    !important
    .rank
      flex-basis: 40px    !important
    .form-group
      margin-bottom: 0
    .path
      flex-basis: 400px !important
      text-align: left !important

  .edit-item
    flex-basis: 100px
    .edit, .actions
      display: block !important
    .text
      display: none !important

  .edit-line
    .state
      width: 105px      !important
      flex-basis: 105px !important
    .quantity
      width: 30% !important
      flex-basis: 30% !important
    .path
      width: 10% !important
      flex-basis: 10% !important

  .btn-toolbar
    border-bottom-left-radius: $border-radius
    border-bottom-right-radius: $border-radius

html
  // compute line-height  to account for a small toolbar button size
  // 0.74375rem is the font-size of a button
  // * 1.5 which is the line-height of a button
  // + 0.5rem for twice the button padding
  // + 2px for twice the button border
  // + 1rem for twice the toolbar padding
  --search-toolbar-height: calc(0.74375rem * 1.5 + 0.5rem + 2px + 1rem)
  --search-toolbar-full-height: var(--search-toolbar-height)
  &.search-panel-opened
    --search-toolbar-full-height: calc(var(--search-toolbar-height) + 1px)


=search-apply
  .searching
    +search-searching
  .search
    +search-search
    +tableview-apply

=search-apply-root
  .search-panel-opened
    --search-size: 5rem
