@import "../../mixins/search"
@import "../../mixins/handler"

html.interface-design
  // Remove by Thomas
  //.searching
    +search-searching

  ////// .browser-search-table
    position: relative !important
    display: flex !important

  .search
    +search-search

  .search-handler
    +handler-top

  .search-handler-over
    +handler-search-over

  .surround-search input[type=search]:not(.multiselect-search)
    width: 300px

  .surround-search .form-switch .form-check-input
    width: 2.5em
    height: 1.5em
    border-radius: 10px

