@media (max-width: 768px)
  .navbar-toggler
    margin-right: 4px
    color: #efefef
    border: none
    &:focus
      box-shadow: none
  .navbar-collapse
    margin-right: 1rem
    display: flex
    gap: 1rem
    .navbar-nav
      flex-direction: row
      gap: 1rem
      .dropdown-menu
        position: absolute

// CHRISTMAS NAVBAR
// Enable ar your own risk
.navbar.main-navbar.christmas-navbar > *
  background: linear-gradient(45deg, #a00000 65%, #ffcc06) !important
.christmas-link
  color: white
  &:hover
    color: #e9fddc


.navbar.main-navbar
  padding: 0
  margin: 0.5rem 0
  color: var(--header-fg-color)
  &, & > *
    background: linear-gradient(45deg, var(--header-bg-color) 65%, var(--header-secondary-bg-color))
  .navbar-brand
    line-height: calc(16px + 1.275rem)
    isolation: isolate
    &, &:hover
      color: var(--header-fg-color)
    padding: 0
    margin: 0
    align-self: stretch
    display: flex
    .logo
      img
        height: 2rem
        min-width: 7rem
      background-color: $body-bg
      z-index: 3
    .spacer
      width: 2rem
      margin-left: -2rem
      transform: skew(-22.5deg)
      position: relative
      background-color: $body-bg
      z-index: 1
    .spacer2
      width: 2rem
      margin-left: -2rem
      transform: skew(-22.5deg) scale(1, 1.05)
      position: relative
      left: calc(1rem - 2px)
      background-color: $body-bg
      z-index: 2
    .interface-name
      font-size: 1.1rem
      padding-left: 2rem
      display: flex
      align-items: center
      font-size: 1rem
  .container-fluid
    padding: 0
  &.navbar-light .navbar-nav .nav-link
    &, &:focus
      color: var(--header-fg-color)
  [data-navbar-target="searchForm"]
    animation: navbar-search 0.5s normal
    &.hidden
      display: none
      animation: navbar-search 0.5s reverse
  .nav-impersonate-notification
    color: $white
    background-color: $danger
    position: fixed
    top: 0
    padding: 0 1rem
    border-bottom-left-radius: 0.5rem
    border-bottom-right-radius: 0.5rem
    left: 50%
    transform: translateX(-50%)
  .dropdown-item.custom-switch
    padding-left: 3.75rem
    margin-left: 0
    label
      font-weight: normal
  // This is not specific enough and breaks buttons from the notification menu
  // disabled because I don't know if it is used at all
  //.btn-outline-secondary:not(:hover)
    color: var(--header-fg-color)
    border-color: var(--header-fg-color)

  .dropdown-menu
    width: max-content
  .dropdown-menu .form-switch
    padding-left: 1em
    .form-check-input
      margin: 2px 5px 0 0

  .nav-eime-version
    display: block
    text-align: center
    font-weight: 500

@keyframes navbar-search
  0%
    display: none
    opacity: 0
  1%
    display: block
    opacity: 0
  100%
    opacity: 1
