@import './overflow'

=treeview-apply($keep-root-on-top: false)
  .tree-body
    min-height: 0
    +treeview-actions
    @if $keep-root-on-top
      +treeview-keep-root-on-top
    @else
      +overflow-scroll-overlay

// Mixin that keeps the first item in the treeview on top of the tree, out of
// the scroll region. the tree must have a single top item. This mixin must be
// applied to .tree-body
=treeview-keep-root-on-top
  display: flex
  flex-flow: column nowrap
  & > ol
    flex: 1 1 auto
    display: flex
    flex-flow: column nowrap
    margin: 0
    min-height: 0
    & > li
      min-height: 0
      flex: 1 1 auto
      display: flex
      flex-flow: column nowrap
      & > input:not(:checked) + label:not(.current)
        background-color: $panel-cap-bg
      & > label
        line-height: 1rem
        padding-top: 0.5rem
        padding-bottom: 0.5rem
        border-bottom: 1px solid $panel-border-color
        overflow: hidden
      & > ol
        flex: 1 1 auto
        +overflow-scroll-overlay
        margin: 0
        min-height: 0

=treeview-actions
  .edit, .actions
    margin-left: 5px
    display: none
  .edit-item > label
    .edit, .actions
      display: block !important
    .text
      display: none !important

