#modal-search
  .modal-title
    width: 100%
    display: inline-flex
  input[type=search]
    border: none
    color: $body-color
    width: 100%
    margin-left: 1rem
    outline: none
    background-color: transparent

  .modal-body
    .results
      max-height: 250px
      overflow-y: auto
