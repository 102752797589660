@import '../mixins/interface'
@import "../mixins/treeview"
@import "../mixins/handler"
@import "../mixins/search"
@import "../mixins/details"
@import '../mixins/overview'
@import "../mixins/toolbar"
@import 'admin/communications'


html.interface-admin
  --selection-bgcolor: var(--header-bg-color)
  --selection-fgcolor: white
  --hover-bgcolor: $gray-300
  --hover-fgcolor: black

  //   100vh  for the full page
  // - 4rem   global header and margins
  --main-panel-height: calc(100vh - 4rem)

  //   var(--main-panel-height)           for the panel available space
  // - var(--toolbar-full-height)         toolbar height with its border
  // - var(--search-toolbar-full-height)  toolbar height with the search
  //                                      panel border if it is opened
  // - 5px                                global overview borders and correction
  // - var(--search-size)                 search panel size if opened
  --overview-height: calc(var(--main-panel-height) - var(--toolbar-full-height))

  .interface-admin
    +interface-apply-root
    +toolbar-apply-root
    +admin-communications

    .panel-group
      .tree
        +handler-resizable-panel
        width: $admin-tree-width
        li
          a
            font-weight: normal
          input[type=radio]:checked + label, input[type=checkbox]:checked + label,.active,.active:hover
            background-color: var(--header-bg-color)
            color: $white
            a
              color: $white
          label.current
            color: $white
            a
              color: $white
          label
            font-weight: normal
          label:hover
            background-color: $gray-300
        +treeview-apply
        li.expanded>ol
          display: block
        .tree-handler
          +handler-right

    +overview-apply

    .overview, .tree
      .panel-body
        height: 100%
        padding: 0 !important
        margin: 0  !important
        .id
          width: 150px
        .full_name, .name
          width: 15%
        .email
          width: 20%
        .role
          width: 11%
        .choose_manager
          width: 12%
        .license_active
          width: 12%
        .restriction
          width: 8%
        .company
          width: 10%
        .instance
          width: 10%
        .table-cell-overflow .c-tableview__cell-data
          max-height: 1em
          white-space: nowrap

        .c-tableview__cell-data div.table-cell-overflow
          display: inline-block
          position: relative
          white-space: normal
          overflow: hidden
          vertical-align: top
          max-height: 1.5em

          & .nowrap
            white-space: nowrap
            text-overflow: ellipsis

          &:hover
            z-index: 1
            width: auto
            max-height: none

    //.search
      min-height: 0

    .c-admin-layout--details-resizing .c-admin-layout__details
      +handler-details-over

    .details
      +details-apply($admin-details-width, $details-max-width)
      +handler-resizable-panel
      left: unset !important // For some reason jquery-ui wnts to set it

      .c-admin-layout__details-handle
        +handler-left

    .admin
      height: calc(var(--main-panel-height))
      +interface-apply

      &.details-hidden .browser.panel
        margin-right: 0

    .overview, .tree
      .panel-body
        padding: 0 !important
        margin: 0  !important

.tree-handler-over
  +handler-tree-over

.search-handler
  +handler-top
.search-handler-over
  +handler-search-over

.multi-select
  .btn-group, .multiselect-container
    width: 100%
