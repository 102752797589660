@import '../mixins/interface'
@import '../mixins/treeview'
@import '../mixins/toolbar'
@import 'design/tree'
@import 'design/library'
//@import 'design/overview'
@import 'design/search'

html.interface-design

  --selection-bgcolor: var(--primary-color)
  --selection-fgcolor: white
  --hover-bgcolor: var(--primary-background-color)
  --hover-fgcolor: black


  --c-tableview--cs-item-id-width:                        40px
  --c-tableview--cs-item-rank-width:                      56px
  --c-tableview--cs-item-ico-width:                       40px
  --c-tableview--cs-item-ecobilan-identifier-width:       82px
  --c-tableview--cs-item-path-width:                      10%
  --c-tableview--cs-item-name-width:                      9%
  --c-tableview--cs-item-quantity-width:                  80px
  --c-tableview--cs-item-quantity-formula-width:          180px
  --c-tableview--cs-item-computed-quantity-width:         80px
  --c-tableview--cs-item-second-quantity-width:           80px
  --c-tableview--cs-item-category-with-inheritance-width: 80px
  --c-tableview--cs-item-use-as-process-width:            40px
  --c-tableview--cs-item-net-calorific-value-width:       80px
  --c-tableview--cs-item-comments-width:                  0
  --c-tableview--cs-item-renewable-p-width:               50px
  --c-tableview--cs-item-loss-rate-percentage-width:      50px
  --c-tableview--cs-item-lr-width:                        50px
  --c-tableview--cs-item-tracking-percent-width:          6em
  --c-tableview--cs-item-tracking-gross-width:            6em
  --c-tableview--cs-item-updated-at-width:                60px
  --c-tableview--cs-item-created-at-width:                60px
  --c-tableview--cs-item-state-width:                     40px

  .c-tableview__body .c-tableview__row.--c-tableview__row--child > input + label
    .c-tableview__cell--name
      margin-left: calc(var(--c-cell--indent--name) * 28px)

  .c-tableview__body .c-tableview__row > input:not(:checked) + label
    .c-tableview__cell--tracking-percent, .c-tableview__cell--tracking-gross
      background-color: #c7505036

  .c-tableview__cell--name
    flex-grow: 1

    .c-tableview__ico
      width: 2em

  #design-search
    min-width: 0
    .c-tableview__cell--path
      flex-grow: 2

  .dropdown-tracking .dropdown-menu
    max-height: calc(100vh - 5rem)
    overflow-y: auto
    width: 350px

    .dropdown-header
      display: list-item

  --search-size: 0px
  +search-apply-root

  //   100vh  for the full page
  // - 4rem   global header and margins
  --main-panel-height: calc(100vh - 4rem)

  turbo-frame#design-library
    display: flex
    flex-flow: row nowrap
    & > *
      flex-grow: 1

    p.no-results
      text-align: center
      font-style: italic
      padding-top: 1rem

  .design
    height: calc(var(--main-panel-height))
    +interface-apply
    +toolbar-apply-root

    .browser.panel .browser-toolbar
      .actions-menu .btn
        background-color: $design-color
        border-color: $design-color

    .btn-toolbar, .tree-header
      flex: 0 1 auto

    .panel-body
      padding: 0 !important
      margin: 0  !important

    .browser
      //flex: 1 !important
      //border: none
      //display: flex
      td.icon
        width: 35px
      .browser-toolbar
        border-top-right-radius: $border-radius !important
        border-top-left-radius: $border-radius !important
      .tree .panel-footer .glyphs
        float: right

  .search > .panel-body
    // makes the search results scroll
    overflow: hidden

  .case-study-nav
    background-color: $primary
    border-radius: $border-radius
    border : $card-border-width solid darken($primary, 10%)
    a.nav-link, span.nav-link
      color: $light
    margin-bottom: calc(#{$grid-gutter-width} / 2)

  .dropdown-tracking
    .dropdown-item.active, .dropdown-item:active
      background-color: theme-color(danger)

  .no-btn
    background-color: transparent
    border: none
    color: $body-color !important

  +interface-current-items

  // Glyphs colors

  $shadow-darken-percentage: 20%


  h1 .glyph
    color: $body-color !important

  .tree, .overview, .library, .search
    label
      width: 100%
      font-weight: normal

  +interface-region

  .context-menu
    kbd
      float: right
      margin-left: 100px

  .tooltip
    kbd
      background-color: white
      color: black
      margin-left: 10px

  .status-history
    height: 90vh
    overflow-y: auto

  //.overview-displaying-buttons
    button
      border-top-right-radius: 0.25rem !important
      border-bottom-right-radius: 0.25rem !important

  .page-item.active .page-link
    background-color: $primary

  .surround-search:after
    position: absolute
    top: .3rem
    right: 2rem
    display: flex
    align-items: center
    justify-content: center
    height: 1.2rem
    padding: .05rem
    padding-right: .25rem
    padding-left: .25rem
    font-size: .65rem
    color: #6c757d
    content: attr(data-shortcut)
    border: 1px solid #dee2e6
    border-radius: .125rem
