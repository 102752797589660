=interface-main-panel
  &.panel-deck
    isolation: isolate
    padding: 0
    margin: 0
    //max-height: attr(height)
    display: flex !important
    position: relative !important
    flex-flow: row nowrap

    & > .panel:first-child
      margin-left: 0
    & > .panel:last-child
      margin-right: 0

    .browser.panel > .panel
      border: 0
      flex-grow: 1
      min-height: 0

=interface-sub-panel
  flex-flow: row nowrap
  flex-direction: row !important
  display: flex !important
  position: relative !important
  //flex: 1 1 auto
  flex: 2
  min-height: 0

=interface-current-items
  .view li label
    .t-cell.bookmark_icon, .t-cell.team
      .glyph:not(.outline)
        color: $body-color

  .view input[type=radio][name=node]:checked + label,
  .view input[type=checkbox][name=node]:checked + label
    color: white !important
    background-color: var(--primary-color)
    a
      color: white
    .tracking
      background-color: #1956ca !important
      color: white !important
    .t-cell:not(.buttons)
      .glyph.outline
        color: var(--primary-color)
        -webkit-text-stroke: 1.2px white
      .glyph:not(.outline)
        text-shadow: 0.5px 0.5px var(--primary-color-dark-20)
        color: white !important
    //.btn-outline-project, .btn-outline-analysis, .btn-outline-design, .btn-outline-comparison
    //  border-color: white !important

  // Remove by Thomas
  // .view input[type=radio][name=node]:focus + label,
  // .view input[type=checkbox][name=node]:focus + label,
  // .view input[type=radio][name=node] + label:focus,
  // .view input[type=checkbox][name=node] + label:focus,
  //   .text
  //     text-decoration: underline dotted

  .view input[type=radio][name=node]:not(:checked):focus + label,
  .view input[type=checkbox][name=node]:not(:checked):focus + label,
  .view input[type=radio][name=node]:not(:checked) + label:focus,
  .view input[type=checkbox][name=node]:not(:checked) + label:focus,
  .view input[type=radio][name=node]:not(:checked) + label:hover,
  .view input[type=checkbox][name=node]:not(:checked) + label:hover
    background-color: var(--primary-background-color)
    .glyph.outline
      color: var(--primary-background-color)

=interface-header-glyph
  h1 .glyph
    color: $body-color !important

=interface-label
  .tree, .overview, .library, .search
    label
      font-weight: normal

=interface-region
  .region
    outline: none

=interface-header
  .nav-btn
    padding-top: 0
    padding-bottom: 0

=interface-apply
  +interface-main-panel
  .panel-group
    +interface-sub-panel

=interface-apply-root
  +interface-current-items
  +interface-header-glyph
  +interface-label
  +interface-region
  header
    +interface-header
