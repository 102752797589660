//
// default variables, file interpreted before any other and it allows to
// customize default variables on ui_bibz and bootstrap CSS.
//

@use "sass:math"

$body-color: #474747 !default
$link-color: #474747 !default
$bg-light: rgba(0,0,0,0.03) !default

// ratio
$ratio: 1.5

// body
$font-size-base: .85rem
$grid-gutter-width: 15px !default

// cards
$card-spacer-x: math.div(1.25rem, $ratio)
$card-spacer-y: math.div(.75rem, $ratio)

// lists
$list-group-item-padding-y: math.div(.75rem, $ratio)
$list-group-item-padding-x: math.div(1.25rem, $ratio)

// tables
$table-cell-padding:     math.div(.75rem, $ratio)
$table-cell-padding-sm:  math.div(.3rem, $ratio)
$table-accent-bg:        #f9f9f9

// panels
$panel-spacer-x: math.div(1.25rem, $ratio)
$panel-spacer-y:  math.div(.75rem, $ratio)
$panel-spacer:  math.div(.75rem, $ratio)

//forms
$form-group-margin-bottom: 15px

// navs
$nav-link-color: $body-color !default

$blue:    #1979ca !default
$indigo:  #6610f2 !default
$purple:  #6f42c1 !default
$pink:    #e83e8c !default
$red:     #c75050 !default
$orange:  #de8233 !default
$yellow:  #ffc107 !default
$green:   #729f57 !default
$teal:    #20c997 !default
$cyan:    #17a2b8 !default
$dark:    #474747 !default
$light:   #e9ecef !default

html
  --cs-container-dossier-color:     #de8233
  --cs-container-subassembly-color: #729f57
  --cs-container-part-color:        #1979ca
  --cs-container-system-color:      #474747
  --cs-container-phase-color:       #c54e50
  --cs-module-color:                #e35a5b
  --cs-module-material-color:       #1e89e3
  --cs-module-component-color:      #768131

html.theme-dark
  --status-color-dark:   #b8b8b8
  --status-color-purple: #6f42c1
  --status-color-green:  #9acd32
  --status-color-red:    #c75050
  --status-color-blue:   #1979ca
  --status-color-yellow: #ffc107

html.theme-light
  --status-color-dark:   #474747
  --status-color-purple: #6f42c1
  --status-color-green:  #9acd32
  --status-color-red:    #c75050
  --status-color-blue:   #1979ca
  --status-color-yellow: #ffc107

// Customize bootstrap to use CSS custom properties for colors that depends on
// the interface
$component-active-bg: var(--primary-color)
$dropdown-link-hover-bg: var(--primary-color-alpha-25)
$input-btn-focus-color: var(--primary-color-alpha-25)
$input-focus-border-color: var(--primary-color-light-25)
$form-range-thumb-active-bg: var(--primary-color-light-35)
$accordion-button-active-bg: var(--primary-color-tint-90)
