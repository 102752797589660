@import "../mixins/treeview"
//@import "../components/c_database_layout"

html.interface-analysis
  --main-panel-height: calc(100vh - 4rem)

  #analysis-overview ol.c-treeview__children > li:nth-of-type(odd)
    background-color: var(--bs-gray-200)

  .analysis
    height: calc(var(--main-panel-height))
    display: flex
    position: relative !important
    padding: 0
    flex: initial
    flex-flow: row nowrap
    //// .tab-pane
      height: calc(100vh - 32vh)
    .no-sort::after, .no-sort::before
      display: none
    .no-sort
      cursor: default

    tr[style="height: 2px;"]
      height: 0px !important

    .DTFC_LeftBodyLiner
      position: inherit !important

    #tree
      flex: initial
      display: flex
      right: 0 !important
      z-index: 999
      .panel-header
        display: inline
      .tree-body
        padding: 0
        overflow: auto
        ol.treeview
          overflow-y: auto
          a.disabled
            pointer-events: none
            cursor: default
            color: #888 !important
          li
            .spinner
              display: none
              position: absolute
              right: 10px
            input[type=checkbox]
              opacity: 1
              transform: none
            .badge
                position: absolute
                border-radius: 1rem
                display: block
                width: 1px
                height: 1px
                padding: 4px
                margin-left: 22px
                margin-top: 4px
            .select-children
              position: absolute
              right: .5rem
              cursor: pointer
            label
              display: flex
              justify-content: flex-start
              div:last-child
                margin-left: auto
          li:not(.active)
            &>label
              font-weight: normal
              &>span.caret
                color: $black !important
              &>a
                color: $black
              &>i
                color: $black
              &>.ico_popover
                color: $secondary
          li.active >label
            background-color: $danger
            &>a
              color: white
            &>i
              color: white
          label:not(.active):hover
            background-color: rgba($danger, 0.25)
        li>label>.ico_popover
          display: none
          padding-rigth: 6px
          padding-left: 6px
          font-size: 0.7rem
          color: $secondary
          &>i
            padding-right: 40px
        li>label:hover>.ico_popover
          display: block
      .tree-body:hover
        overflow: overlay
      .tree-footer, .tree-footer:hover
        overflow: none !important

    overflow: hidden

    .chart-tab
      a
        display: inline-flex
    .tab-content, .tab-content > div, .tab-content .row, tab-content .row div
      height: 100%
    .values-body
      background-color: #e6e6e6
      padding: 0 !important
      #all-indicators-charts
         background-color: white
      .table, .table tr
        //border: none
      .table-bordered
        margin-top: 0px !important
        margin-bottom: 0px !important
        td:first-child
          border-left: none
          white-space: nowrap
        td:last-child, th:last-child
          border-right: none

        .narrow-cells td
          padding: 0.2rem 0.5rem

        .flow
          strong, em, span
            width: 120px
            text-overflow: ellipsis
            white-space: nowrap
            overflow: hidden
            display: block

      .panel-header-tabs
        width: 100%

    .chart-body
      display: none

    .tab-pane
      .btn-toolbar
        background-color: #f7f7f7
        border-bottom: 1px solid #0000002d

    .table-loader
      width: 4rem
      height: 4rem

    #chart-loader
      position: absolute
      top: 42px
      left: 5px

    .values, .values-body .panel-footer
      position: relative
      +overflow-scroll-overlay

    .dropdown-menu
      max-height: 500px
      overflow-y: auto

    .tree-handler-over
      box-shadow: inset 13px 0px 20px -20px black

    .table:not(.no-webpack) tbody tr:nth-of-type(odd) td
      background-color: darken($body-bg, 5)

    .table:not(.no-webpack) tbody tr:nth-of-type(even) td
      background-color: $body-bg

    .table:not(.no-webpack) td, .table:not(.no-webpack) th, .table
      border: none !important

    .table:not(.no-webpack) thead th
      // this replaces the border
      box-shadow: inset -1px 1px 0px 0px $border-color
      small
        font-style: italic
        font-size: 0.60rem

    .table:not(.no-webpack) tbody td,
    .table:not(.no-webpack) tbody th
      white-space: nowrap
      // this replaces the border
      box-shadow: inset -1px 1px 0px 0px $border-color
      vertical-align: middle

    .table:not(.no-webpack) tbody td:not(:first-child),
    .table:not(.no-webpack) tbody th:not(:first-child),
    .table:not(.no-webpack) tfoot tr th:not(:first-child),
    .table:not(.no-webpack) tfoot tr td:not(:first-child)
      text-align: right

    .table:not(.no-webpack) tfoot tr th,
    .table:not(.no-webpack) tfoot tr td
      background-color: darken($body-bg, 10)
      box-shadow: none

    .analysis .settings
      padding: 20px
      background-color: $panel-cap-bg
      border-left: 1px solid $border-color
    //.analysis .tab-pane
      background-color: white

    .display-popover-menu
      position: absolute
      width: 300px
      transform: translate3d(-245px, 28px, 0px)
      top: 0px
      left: 0px
      will-change: transform
      .arrow
        left: 245px

    .tableview-options-menu-toggle
      border-top-left-radius: $border-radius !important
      border-bottom-left-radius: $border-radius !important

    table:not(.no-webpack) td.negative, table:not(.no-webpack) th.negative
      background-color: lighten($success, 30) !important

    .dataTables_wrapper
      background: linear-gradient($body-bg, darken($body-bg, 10))

    .dataTables_scrollHeadInner
      background-color: $dark
      box-shadow: inset -1px 1px 0px 0px $border-color

    .dataTables_scrollFootInner
      background-color: darken($body-bg, 10)

    .dataTables_paginate > ul.pagination
      justify-content: center
      margin-top: .5rem

    .dataTables_length
      float: left
      margin-left: .5rem
      margin-top: .75rem

    .dataTables_filter
      float: right
      margin-right: .5rem
      margin-top: .75rem

    tr.table-total th,
    tr.table-total td
      background-color: lighten($danger, 40) !important

    tr.subtotal-negative th,
    tr.subtotal-negative td
      background-color: lighten($success, 40) !important

    tr.subtotal-positive th,
    tr.subtotal-positive td
      background-color: #f9f9f9 !important

    // .table > :not(caption) > * > *
    //   box-shadow: none

    // .table-danger td:not(:nth-child(-n+3))
    //   background: repeating-linear-gradient(45deg, transparent, transparent 10px, lighten($danger, 40) 10px, lighten($danger, 40) 20px)

    .table-danger td
      background-color: lighten($danger, 40) !important

    td.threshold-danger
      background-color: lighten($danger, 40) !important

    tr.total th
      box-shadow: inset -1px 1px 0px 0px $border-color

    tr.subtotal th
      box-shadow: inset -1px 1px 0px 0px $border-color

    tr.table-total th
      box-shadow: inset -1px 1px 0px 0px $border-color

    .table-footer
      th:first-child
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        min-width: 20px
        max-width: 130px

    span[data-subdata]
      font-weight: bold

    span[data-subdata]:before
      font-weight: 200
      font-style: italic
      content: attr(data-subdata)
      display: block
      white-space: pre-wrap
    .flow span[data-subdata]:before
      width: 120px

    .table:not(.no-webpack)
      th.gross, td.gross
        min-width: 38px

      .gross, .percent, .sum
        white-space: nowrap

  ul.detail
    margin: 0
    padding: 0
    li
      list-style: none
  .values .caret
    width: 15px !important
    line-height: 0.5rem
    height: 12px
    flex: none
    &:before
      -webkit-font-smoothing: antialiased
      font-family: "Font Awesome 6 Free"
      font-weight: 900
      content: "\F0DA"
      position: static
  ol.breadcrumb
    margin: 0

  .threshold_class
    input
      width: 3rem
    select
      width: 8rem
  .dropdown-formatdata
    .dropdown-menu.show
      padding: 0
      min-width: 15rem

  .comparison-items-form
    width: 125px
