=overflow-scroll-overlay
  overflow: hidden
  overflow: overlay
  &:hover
    scrollbar-width: 12px
    overflow-x: auto
    overflow-y: scroll
    overflow-x: overlay
    overflow-y: overlay

=overflow-y-scroll-overlay
  overflow-y: hidden
  overflow-y: overlay
  &:not(:hover) > *
    padding-right: 12px
  &:hover
    scrollbar-width: 12px
    overflow-y: scroll
    overflow-y: overlay

    @supports (overflow-y: overlay)
      padding-right: 12px
      & > *
        padding-right: 12px
