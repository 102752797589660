=overview-header
  border-top-right-radius: 0px
  padding-top: 0
  padding-bottom: 0
  padding-left: 0
  nav
    line-height: 1
    min-height: 1rem
    flex: 0 1 auto
    padding-left: 0.5rem
    padding-top: 0.5rem
    padding-bottom: 0.5rem
    align-self: center
    &:hover
      overflow: none
      overflow-x: overlay
      scrollbar-width: thin
      // Remove by Thomas
      // padding-bottom: calc(0.5rem - 10px)
    &:not(:hover)
      overflow: hidden
    .breadcrumb
      padding-left: 0.5rem
      flex-wrap: nowrap
      display: inline-flex
      background-color: transparent
      margin: 0 10px 0 0
      padding: 0
      li
        flex: 0 0 auto
      .active
        .glyph
          color: #868e96
  .btn-toolbar
    flex: 0 0 auto
    margin: 0.25rem
    // Remove by Thomas
    //.btn
      padding: 0.125rem

=overview-parent
  min-height: 0
  +tableview-apply

=overview-body
  min-height: 0
  position: relative !important
  display: flex !important

=overview-apply
  .overview-header
    +overview-header
  .overview-body
    +overview-body
  .overview
    +overview-parent
