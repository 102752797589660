=tableview-row($head: false, $body: false)
  .badges
    display: flex
    flex-wrap: nowrap
  .badge
    padding: 0.28rem
    margin-right: 5px
    width: fit-content
  .check
    flex-basis: 40px
    text-align: center
  .t-cell.team
    flex-basis: 5em
    display: flex
    justify-content: space-around
    flex-wrap: nowrap
  .ico, .share_icon, .bookmark_icon, .status
    text-align: center
    i
      justify-content: center
  .t-cell.ico
    flex-basis: 40px
  .share_icon, .bookmark_icon, .status
    flex-basis: 2em
  .ico.edit-size
    padding: 0.7rem 0
  .t-cell.name
    flex-basis: 10% //fit-content
    flex-grow: 1
    text-align: left
    @if $body
      padding: 0
      .edit, .text > *:not(.buttons)
        margin: 0.5em
    .text
      span.name
        overflow: hidden
        flex-basis: min-content
      display: flex
      flex-wrap: nowrap
      .badges
        flex-grow: 1
      .buttons
        align-self: center
    a
      margin-right: 5px
      word-wrap: wrap
  .lr, .use_as_process
    flex-basis: 70px
  .state
    flex-basis: 35px
    span
      justify-content: center
  .quantity, .second_quantity
    //flex-basis: 150px
    max-width: 150px
    span, .quantity-content
      justify-content: center
    .quantity-content
      max-width: 120px
    .text
      display: inline-flex
      .unit
        width: 10px
        display: block
      .ellipsis
        padding-right: 3px
        text-align: right
        max-width: 90px
        display: block
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis
  .user
    span
      justify-content: left
  .id
    flex-basis: 260px
    text-align: left
  .created_at, .updated_at, .db_module_released_at
    flex-basis: 135px
  .user
    flex-basis: 135px
    text-align: left
  .t-cell.buttons
    flex-basis: 90px
    @if $body
      padding-top: 0.18em
      padding-bottom: 0.18em
  .tracking, .tracking-percent, .tracking-gross
    flex-basis: 80px
  .path, .comments
    text-align: left
  .comments
    flex-basis: 50px
  .rank
    flex-basis: 40px
    text-align: center
  .ecobilan_identifier
    flex-basis: 90px
  .renewable_percentage, .net_calorific_value
    flex-basis: 50px
  .form-group
    margin-bottom: 0
  //.edit-size
    flex-basis: auto !important

  // Revert line-height: 0 which cause issues in tableview badges and headers
  .t-cell > span > i.fas, .badge > i.fas
    line-height: unset !important

=tableview-edit-item
  flex-basis: 100px
  .edit, .actions
    display: block !important
  .text
    display: none !important

=tableview-actions
  margin-left: 5px
  display: none

=tableview-apply
  .display-list
    .t-head
      border: none
      .t-row
        +tableview-row($head: true)
    .t-body
      display: flex
      flex-flow: column nowrap
      .t-row
        flex-basis: auto
        +tableview-row($body: true)
        input + label
          border: thin solid transparent
        // Remove by Thomas
        //input:focus + label
          border: thin dotted red
      .t-extra-row
        min-height: 4rem
        flex: 1 0 4rem
  .edit-item
    +tableview-edit-item
  .edit, .actions
    +tableview-actions
