html.interface-make

  table.permissions
    td
      vertical-align: middle
      .form-group
        margin: 0
      input[type=submit]
        width: 100%

  .add-new-member
    .ui_dropdown_select_field
      .dropdown-select-field
        display: block
        width: 100% !important

  .ui_multi_select_field.cs_project_indicators_sets .btn-group
    min-width: 30em
  .ui_multi_select_field.cs_project_tags .btn-group
    min-width: 20em
