//
// Application variables, evaluated after ui-bibz and Bootstrap CSS so bootstrap
// variables are accessible.
//

=global_variables($primary, $default-header-color, $default-header-background-color, $default-header-secondary-background-color)
  --primary-color: #{$primary}
  --primary-color-alpha-15: #{rgba($primary, 15%)}
  --primary-color-alpha-25: #{rgba($primary, 25%)}
  --primary-color-light-25: #{lighten($primary, 25%)}
  --primary-color-light-35: #{lighten($primary, 35%)}
  --primary-color-tint-90: #{tint-color($primary, 90%)}
  --primary-color-dark-16: #{darken($primary, 16%)}
  --primary-color-dark-20: #{darken($primary, 20%)}
  --primary-background-color: #{rgba($primary, 0.4)}
  --header-fg-color: #{$default-header-color}
  --header-bg-color: #{$default-header-background-color}
  --header-light-bg-color: #{lighten($default-header-background-color, 15%)}
  --header-secondary-bg-color: #{$default-header-secondary-background-color}
  --header-light-secondary-bg-color: #{lighten($default-header-secondary-background-color, 20%)}
  --header-border-color: #{darken($default-header-background-color, 10%)}
  --header-light-border-color: #{darken($default-header-background-color, 2%)}

// Common --------------------------------

$default-color: gray !default
$default-header-background-color: gray !default
$default-header-secondary-background-color: gray !default
$default-header-color: white !default

$details-max-width: 50% !default

html
  +global_variables($primary, $default-header-color, $default-header-background-color, $default-header-secondary-background-color)
  --primary-color-contrasted: #f7f7f7
  --secondary-color: #{$secondary}
  --secondary-color-contrasted: white
  --dark-color: #{$dark}
  --dark-color-contrasted: white
  --light-color: #{$light}
  --light-color-contrasted: black
  --scrollbar-color: #{$secondary}
  --scrollbar-color-contrasted: #{$gray-200}
  --panel-bg: rgba(0, 0, 0, 0.05)
  --body-bg: #{$body-bg}
  --body-bg-dark5: #{darken($body-bg, 5)}
  --body-fg: #{$dark}
  --border-color: #{$gray-300}
  --border-radius: 0.25rem
  --focus-border-color: #515151
  --panel-head-bg: #f7f7f7
  --panel-border-color: #d8d8d8
  --card-border-color: $card-border-color
  --card-cap-bg: $card-cap-bg
  --danger-color: var(--bs-danger)

  // Overview
  --overview-list-bg: white

  // Forms
  --form-bg: #eeeeee
  --form-steps-outline-color: #{$dark}
  --form-steps-bg: #{$light}
  --form-steps-bg-contrasted: #{$dark}
  --form-steps-fg: #{$dark}
  --form-steps-fg-contrasted: #{$light}

  --success-color: #{$success}
  --success-light30-color: #{lighten($success, 30)}
  --success-light35-color: #{lighten($success, 35)}
  --success-light40-color: #{lighten($success, 40)}
  --success-light45-color: #{lighten($success, 45)}
  --success-light50-color: #{lighten($success, 50)}
  --success-light55-color: #{lighten($success, 55)}
  --success-light60-color: #{lighten($success, 60)}
  --analysis-negative-cell-bg: var(--success-light40-color)
  --analysis-negative-cell-striped-bg: var(--success-light30-color)

// Admin ---------------------------------

$admin-color: $dark !default
$admin-color-contrasted: white !default
$admin-primary-color: $dark !default
$admin-header-color: white !default
$admin-header-background-color: $dark !default

$admin-dark-color: $body-color !default
$admin-dark-primary-color: black !default
$admin-dark-header-color: black !default
$admin-dark-header-background-color: $body-color !default

$admin-tree-width:        15em !default
$admin-details-width:     20% !default

html.interface-admin
  +global_variables($admin-primary-color, $admin-header-color, $admin-header-background-color, $admin-header-background-color)
html.interface-admin.theme-dark
  +global_variables($admin-dark-primary-color, $admin-dark-header-color, $admin-dark-header-background-color, $admin-header-background-color)

// Header Background color ---------------
$project-header-background-color: #729f57 !default
$design-header-background-color: #1979ca !default
$database-header-background-color: #de8233 !default
$analysis-header-background-color: $red !default
$comparison-header-background-color: #7a5f93 !default
$home-header-background-color: #11575B !default

// Analysis ------------------------------

$analysis-color: $red !default
$analysis-primary-color: darken($analysis-color, 10%) !default
$analysis-header-color: #e9ecef !default

html.interface-analysis
  +global_variables($analysis-primary-color, $analysis-header-color, $analysis-header-background-color, $database-header-background-color)

// Comparison ----------------------------

$comparison-color: #7a5f93 !default
$comparison-primary-color: darken($comparison-color, 10%) !default
$comparison-header-color: white !default

html.interface-comparison
  +global_variables($comparison-primary-color, $comparison-header-color, $comparison-header-background-color, $analysis-header-background-color)

// Database ------------------------------

$database-color: #de8233 !default
$database-primary-color: darken($database-color, 10%) !default
$database-header-color: white !default

html.interface-database
  +global_variables($database-primary-color, $database-header-color, $database-header-background-color, $red)

// Design --------------------------------

$design-color: #1979ca !default
$design-primary-color: darken($design-color, 10%) !default

$design-header-color: $light !default

$design-browser-width:      70%
$design-color:       $primary

html.interface-design
  +global_variables($design-primary-color, $design-header-color, $design-header-background-color, $project-header-background-color)

// Project -------------------------------

$project-color: #729f57 !default
$project-color-contrasted: white !default
$project-primary-color: darken($project-color, 10%) !default
$project-header-color: white !default

$project-tree-width:        15em !default
$project-details-width:     20% !default

html.interface-project
  +global_variables($project-primary-color, $project-header-color, $project-header-background-color, $design-header-background-color)

// Database ------------------------------
$database-details-width: 30% !default

// Users (team) --------------------------

$users-color: $green !default

// Home --------------------------
$home-primary-color: #6C757D !default
$home-header-color: white !default

html.interface-home
  +global_variables($home-primary-color, $home-header-color, $home-header-background-color, $design-header-background-color)
