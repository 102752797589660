@import "../../mixins/treeview"
@import "../../mixins/handler"

html.interface-design
  .design
    .panel-group
      .tree
        background-color: $card-bg
        +handler-resizable-panel
        //border-top-left-radius: 0px
        //margin-right: $grid-gutter-width/2
        .tree-header
          display: none
          .btn-search
            display: flex
            flex: 1 1 auto

        // Remove by Thomas
        //& > .panel-footer
          padding: 0.25rem

        +treeview-apply($keep-root-on-top: true)

        .tree-body
          flex: 1 1 100%
          .list-group-item
            background-color: transparent
            margin: 0
            padding: 0

            .surround_field
              margin: 0.2rem


          //border: none
          // .tree-list-search-items
          //   .list-group-item
          //     border-top: $list-group-border-width solid $list-group-border-color
          //   .list-group-item:nth-of-type(odd)
          //     background-color: white
          //   .list-group-item:nth-of-type(even)
          //     background-color: $table-accent-bg
          // .list-group-item:first-child
          //   border-top: 0px
          // .list-group-item
          //   padding: $list-group-item-padding-y/$ratio $list-group-item-padding-x/$ratio
          //   white-space: nowrap
          //   background-color: transparent
          //   border-radius: 0px
          //   border-right: 0px
          //   border-left: 0px
          //   border-top: 1px solid transparent
          //   border-bottom: 1px solid transparent
          //   cursor: pointer
            .glyph:first-child
              margin-right: 10px

            .text-highlight
              background-color: $warning
              font-weight: bold

          .overview
            overflow: auto
            flex: 1 !important


