@import '../mixins/overview'
@import '../mixins/overflow'
@import '../mixins/interface'
@import "../mixins/treeview"
@import "../mixins/handler"
@import "../mixins/details"
@import "../mixins/toolbar"

html.interface-project
  --selection-bgcolor: var(--primary-color)
  --selection-fgcolor: white
  --hover-bgcolor: rgba(114, 159, 87, 0.4) /*// project-color with 0.4 opacity */
  --hover-fgcolor: black

  --c-tableview--cs-item-id-width:                    240px
  --c-tableview--cs-item-rank-width:                  40px
  --c-tableview--cs-item-bookmark-icon-width:         2.75rem
  --c-tableview--cs-item-name-width:                  10%
  --c-tableview--cs-item-user-width:                  135px
  --c-tableview--cs-item-updated-at-width:            135px
  --c-tableview--cs-item-created-at-width:            135px
  --c-tableview--cs-item-team-width:                  5em
  --c-tableview--cs-item-buttons-width:               90px
  --c-tableview--cs-item-state-width:                 40px
  --c-tableview--cs-item-db-module-released-at-width: 135px

  .c-project-layout__overview .c-tableview__cell--name
    flex-grow: 1

  .c-project-layout__search
    --c-tableview--cs-item-name-width: 40%
    --c-tableview--cs-item-path-width: 40%
    .c-tableview__cell--path
      flex-grow: 1
    .c-tableview__extra-row
      display: none

  .c-tableview__cell--team .c-tableview__cell-data
    display: flex
    flex-flow: row nowrap
    justify-content: space-around
    gap: 4px
  .c-tableview__body .c-tableview__cell--buttons .c-tableview__cell-data
    padding-top: 0
    padding-bottom: 0

  // Fix user list too long
  [data-controller~="project-filter-user"] ul.multiselect-container
    // arbitrary max height as component does not handle scrolling
    max-height: calc(100vh - 5rem)
    overflow-y: scroll

  //--search-size: 0px
  //+search-apply-root

  //   100vh  for the full page
  // - 4rem   global header and margins
  --main-panel-height: calc(100vh - 4rem)

  .interface-project
    +interface-apply-root
    +toolbar-apply-root

    .panel-group
      min-height: 0
      .tree

        background-color: $panel-bg
        width: $project-tree-width

        +treeview-apply

    //.search
      min-height: 0

    .details
      +details-apply($project-details-width, $details-max-width)
      +handler-resizable-panel
      left: unset !important // For some reason jquery-ui wnts to set it

    .project
      height: calc(var(--main-panel-height))
      +interface-apply

      &.details-hidden .browser.panel
        margin-right: 0

    .case-study-nav
      background-color: $success
      color: white
      border-radius: $border-radius
      border : $card-border-width solid darken($success, 10%)
      a.nav-link
        color: $light
      margin-bottom: calc(#{$grid-gutter-width} / 2)

    +overview-apply

    .overview, .tree
      .panel-body
        padding: 0 !important
        margin: 0  !important

  .c-tableview__row input:checked + label .badge-project
    background-color: $project-color-contrasted
    color: $project-color

  .c-tableview__row input:checked + label .badge-project i
    color: $project-color !important

  .breadcrumb .badge
    margin-left: 0.5rem
