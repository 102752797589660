@import ../mixins/overflow
@import ../mixins/tableview

.tableview
  flex-direction: column
  width: 100%
  display: flex !important

  .t-head
    padding-right: 12px // compensate a little scrollbar width
    border-top: 1px solid $dark
    border-bottom: 1px solid $dark
    position: relative !important
    background-color: $dark
    flex: 1 1 auto
    border-bottom: 1px solid $border-color
    & .t-cell
      color: white
      font-weight: bold

    //&:hover
      .t-cell
        border-right: 1px solid $border-color

  .t-body:not(.t-draggable)
    +overflow-y-scroll-overlay
    position: relative !important
    //flex: 1 1 100%
    flex: 100 1 auto

  .t-body
    & label
      padding: 0
      margin: 0
      width: 100%
      & form
        display: flex
        padding: 0
        flex-basis: 100%

    & input[type=checkbox], input[type=radio]
      //visibility: hidden
      // trick the browser in thinking the input is visible so it is focusable
      transform: translate(-0.5em, -0.5em) scale(0.1)
      position: absolute
      opacity: 0
      width: 0
      height: 0
      margin: 0
      padding: 0

    .t-row:first-child
      border-top: none

  .t-row
    display: flex
    //flex-wrap: wrap
    padding: 0
    flex-basis: 100%

    &.ui-sortable-placeholder
      flex-shrink: 0

    .t-cell
      text-align: center
      flex-basis: auto
      padding: 0.5em
      overflow: hidden
      white-space: nowrap
      text-overflow: clip ellipsis
      width: 100%
      span:not(.c-glyph-badge)
        display: flex
      a
        overflow: hidden
        white-space: nowrap
        min-width: 0
        text-overflow: ellipsis

.tableview.display-list
  .t-body
    .t-row
      border-bottom: 1px solid $border-color

    .t-row:nth-of-type(even)
      //background-color: $panel-bg
      .glyph.outline
        color: $panel-bg
    .t-row:nth-of-type(odd)
      background-color: rgba(0, 0, 0, 0.05)
      .glyph.outline
        color: rgba(0, 0, 0, 0.05)

.tableview .t-body .t-row.disabled, .treeview li.disabled
  background-color: rgb(249,249,249)
  a
    color: grey
  color: grey
  .glyph
    color: grey !important
