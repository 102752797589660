html.interface-users
  &, .login
    height: 100%

  .login
    display: flex
    align-items: center
    justify-content: space-around
    flex-direction: column

  .devise-extend
    max-width: 360px
    width: 360px
    margin: auto

  .devise.sign-in
    padding-top: 24rem
    background: url(~images/eime6-vertical.svg) center top/60% no-repeat
    .form-label-group
      margin-bottom: 0px !important
      input[type=email]
        margin-bottom: -1px
        border-bottom-right-radius: 0
        border-bottom-left-radius: 0
      input[type=password]
        border-top-right-radius: 0
        border-top-left-radius: 0
    .user_remember_me
      margin-top: 15px

  .sign-in-deck
    max-width: 800px

    .card
      img
        width: 50px !important
        float: left
        margin-right: 10px

  .login
    background-color: #f5f5f5

  footer.devise
    padding-top: 10px

  .view-users-index
    .license-expired
      &, a
        color: grey
    i.fas.expired
      color: $danger
    i.fas.not-expired
      color: $success

  .form-label-group
    position: relative
    label
      position: absolute !important
      top: 0
      left: 0
      display: block
      width: 100%
      color: #495057
      pointer-events: none
      cursor: text
      border: 1px solid transparent
      border-radius: .25rem
      transition: all .1s ease-in-out
      position: static
    input
      &::-webkit-input-placeholder
        color: transparent
      &::-moz-placeholder
        color: transparent
      &::-ms-input-placeholder
        color: transparent
        color: #777
      &::placeholder
        color: transparent
      &:not(:-moz-placeholder-shown)
        padding-top: 1.25rem
        padding-bottom: .25rem
        & + label
          padding-top: .25rem
          padding-bottom: .25rem
          font-size: 12px
          color: #777
      &:not(:placeholder-shown)
        padding-top: 1.25rem
        padding-bottom: .25rem
        & ~ label
          padding-top: .25rem
          padding-bottom: .25rem
          font-size: 12px
          color: #777

  .form-label-group input,
  .form-label-group label
    height: 3.125rem
    padding: .75rem

  @supports (-ms-ime-align:	auto)

    .form-label-group
      display: flex
      flex-direction: column-reverse

    .form-label-group label
      position: static

    .form-label-group input::-ms-input-placeholder
      color: #777
